import { useState, useEffect } from 'react';
import axios from 'axios';
import CompanyListItem from './CompanyListItem';
import { useAuth } from '../../contexts/AuthContext';
import { useChainId } from 'wagmi';

export default function CompanyList() {
  const [searchTerm, setSearchTerm] = useState('');
  const [tokens, setTokens] = useState<any[]>([]);
  const { auth } = useAuth();
  const chainId = useChainId();
  const [logos, setLogos] = useState<{ [key: string]: string }>({});

  /**
   * Fetch tokens
   */
  useEffect(() => {
    (async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/v1/accounts/me/tokens?chain_id=${chainId}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        const result = response.data.result;
        setTokens(result);

        // Fetch corporate logos for each certificate
        const logoPromises = result.map(async (token: any) => {
          const logoResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/business_entities/${token.issuer_id}/logo`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth?.user.account_id,
              },
              responseType: 'arraybuffer',
            }
          );
          const contentType = logoResponse.headers['content-type'];
          const base64Logo = Buffer.from(logoResponse.data).toString('base64');
          const logoSrc = `data:${contentType};base64,${base64Logo}`;

          return { issuerId: token.issuer_id, logoSrc };
        });

        const logosData = await Promise.all(logoPromises);
        const logoMap: { [key: string]: string } = {};
        logosData.forEach(({ issuerId, logoSrc }) => {
          logoMap[issuerId] = logoSrc;
        });

        setLogos(logoMap);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  // Filter tokens based on the search term
  const filteredTokens = tokens.filter((token) =>
    token.token_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mt-4">
      <section className="">
        <div className="row g-3 d-flex justify-content-between">
          <div className="col-auto">
            <input
              className="form-control mb-3"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-auto">
            {/* <button className="btn btn-primary" disabled>Request Token</button> */}
          </div>
        </div>
        <div className="company-list-grid">
          {filteredTokens.length === 0 && <div>No markets to display</div>}
          {filteredTokens.map((token, index) => (
            <CompanyListItem
              key={index}
              name={token.token_name}
              image={logos[token.issuer_id]}
              issuer={token.token_issuer}
              symbol={token.token_symbol}
              address={token.token_address}
              type={token.asset_subtype}
              url={token.url || ''}
              description={token.token_description}
              price={token.price || 0}
              valuation={token.valuation || 0}
              valuationAsk={token.valuationAsk || 1_000_000}
              valuationMethod={token.valuationMethod || ''}
              valuationString={token.valuationString || ''}
              valuationDate={token.valuationDate || ''}
              circulatingSupply={token.circulatingSupply}
              authorizedSupply={10_000_000}
              badgeType={
                token.badgeType ||
                (token.asset_subtype === 'safe' && 'Primary') ||
                'Secondary'
              }
            />
          ))}
        </div>
      </section>
    </div>
  );
}
