import React, { useState, useEffect } from 'react';
import {
  useWriteContract,
  useWaitForTransactionReceipt,
  useChainId,
  useAccount,
  usePublicClient,
} from 'wagmi';
import Container from 'react-bootstrap/Container';
import { contracts } from '../../constants';
import Form from 'react-bootstrap/Form';
import { Button, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { useError } from '../../contexts/ErrorContext';
import { useNavigate, useParams } from 'react-router-dom';

function RegisterTransferAgent() {
  const chainId = useChainId();
  const { address: account } = useAccount();
  const [agentAddress, setAgentAddress] = useState('');
  const { auth } = useAuth();
  const [identityRegistry, setIdentityRegistry] = useState<
    string | undefined
  >();
  const { setError } = useError();
  const navigate = useNavigate();
  const { tokenId } = useParams();
  const [loading, setLoading] = useState(false);
  const publicClient = usePublicClient();

  // Fetch token
  useEffect(() => {
    (async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens?chain_id=${chainId}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        const { result } = response.data;
        setIdentityRegistry(result[0].token_registry);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token, chainId]);

  const {
    data: registerAgentTxHash,
    writeContractAsync: registerAgent,
    isPending: isRegisterAgentLoading,
  } = useWriteContract();

  const { data: registerAgentReceipt, isLoading: isRegisterAgentTxLoading } =
    useWaitForTransactionReceipt({
      hash: registerAgentTxHash,
    });

  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!account || !chainId || !agentAddress) {
      alert('Please check your wallet connection and input.');
      return;
    }

    try {
      const args = [agentAddress];
      const gas = await publicClient?.estimateContractGas({
        account: account,
        address: identityRegistry as `0x${string}`,
        abi: contracts[chainId!]?.IdentityRegistry.abi,
        functionName: 'addAgent',
        args,
      });
      const result = await registerAgent({
        account,
        address: identityRegistry as `0x${string}`,
        abi: contracts[chainId!]?.IdentityRegistry.abi,
        functionName: 'addAgent',
        args,
        gas,
      });

      console.log({ result });

      // Add account to transfer agent registry in database
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens/${tokenId}/transfer_agents`,
        {
          agent_address: agentAddress,
          chain_id: chainId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      console.log({ response });

      alert('Transfer Agent registered successfully.');
      setAgentAddress('');
      navigate(-1);
    } catch (error) {
      console.error('Registration failed:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {/* Breadcrumb */}
      <div className="border-bottom py-3 mb-4">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/equity/${tokenId}`}>{tokenId}</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/equity/${tokenId}/identities`}>Registry</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Register Transfer Agent
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <Card className="col-4 offset-4 mt-5">
        <Card.Header>
          <h4 className="mb-0">Register Transfer Agent</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleRegister}>
            <Form.Group className="mb-3" controlId="agentAddress">
              <Form.Label>Transfer Agent Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter transfer agent address"
                value={agentAddress}
                onChange={(e) => setAgentAddress(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              disabled={
                loading || isRegisterAgentLoading || isRegisterAgentTxLoading
              }
            >
              {loading || isRegisterAgentLoading || isRegisterAgentTxLoading ? (
                <span className="fa fa-spin fa-spinner" />
              ) : (
                'Register Transfer Agent'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default RegisterTransferAgent;
