import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthContext';
import { Card, Container, Button, Row, Col, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const ReviewOffering: React.FC = () => {
  const { auth } = useAuth();
  const { offeringId } = useParams();
  const navigate = useNavigate();
  const [offering, setOffering] = useState<any>(null);
  const [safeTerms, setSafeTerms] = useState<any>(null);
  const [documents, setDocuments] = useState<any[]>([]);

  useEffect(() => {
    const fetchOfferingDetails = async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        setOffering(response.data.result);
        setSafeTerms(response.data.result.safe_terms);
      } catch (error) {
        console.error('Error fetching offering details:', error);
      }
    };
    fetchOfferingDetails();
  }, [auth?.token, offeringId]);

  const handleSubmitForApproval = async () => {
    try {
      await axios({
        url: `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/submit`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'X-Account-Id': auth?.user.account_id,
        },
      });
      navigate(`/offerings/${offeringId}`);
    } catch (error) {
      console.error('Error submitting offering for approval:', error);
    }
  };

  if (!offering) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="my-5 p-5">
      <Helmet>
        <title>Review Offering | {offering.offering_name}</title>
      </Helmet>
      <h2 className="mb-4">Review Offering</h2>

      {/* Offering Details Card */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Row>
            <Col md={6}>
              <Card.Title>Offering Name</Card.Title>
              <Card.Text>{offering.offering_name}</Card.Text>
            </Col>
            <Col md={6}>
              <Card.Title>Offering Type</Card.Title>
              <Card.Text>{offering.offering_type}</Card.Text>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Card.Title>Description</Card.Title>
              <Card.Text>{offering.offering_description}</Card.Text>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Card.Title>Use of Funds</Card.Title>
              <Card.Text>{offering.use_of_funds}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Financial Details Card */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Row>
            <Col md={4}>
              <Card.Title>Exemption</Card.Title>
              <Card.Text>{offering.federal_exemption}</Card.Text>
            </Col>
            <Col md={4}>
              <Card.Title>Soft Cap</Card.Title>
              <Card.Text>${offering.soft_cap}</Card.Text>
            </Col>
            <Col md={4}>
              <Card.Title>Hard Cap</Card.Title>
              <Card.Text>${offering.hard_cap}</Card.Text>
            </Col>
          </Row>
          {offering.offering_type !== 'SAFE' && (
            <Row className="mt-3">
              <Col md={4}>
                <Card.Title>Offering Price</Card.Title>
                <Card.Text>${offering.offering_price} per share</Card.Text>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      {/* SAFE Terms Card */}
      {offering.offering_type === 'SAFE' && safeTerms && (
        <Card className="mb-4 shadow-sm">
          <Card.Body>
            <Card.Title>SAFE Terms</Card.Title>
            <Row className="mt-3">
              <Col md={6}>
                <Card.Subtitle className="mb-2">Discount</Card.Subtitle>
                <Card.Text>{safeTerms.discount}%</Card.Text>
              </Col>
              <Col md={6}>
                <Card.Subtitle className="mb-2">Valuation Cap</Card.Subtitle>
                <Card.Text>${safeTerms.valuation_cap}</Card.Text>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Card.Subtitle className="mb-2">
                  Most Favored Nation
                </Card.Subtitle>
                <Card.Text>
                  {safeTerms.most_favored_nation ? 'Yes' : 'No'}
                </Card.Text>
              </Col>
              <Col md={6}>
                <Card.Subtitle className="mb-2">Investment Token</Card.Subtitle>
                <Card.Text>{safeTerms.investment_token || 'N/A'}</Card.Text>
              </Col>
            </Row>
            {/* Include other SAFE terms as needed */}
          </Card.Body>
        </Card>
      )}

      {/* Documents Card */}
      <Card className="mb-4 shadow-sm">
        <Card.Body>
          <Card.Title>Documents</Card.Title>
          {documents.length > 0 ? (
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Document Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((doc) => (
                  <tr key={doc.document_id}>
                    <td>{doc.document_type}</td>
                    <td>
                      <a
                        href={doc.document_uri}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Document
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No documents uploaded.</p>
          )}
        </Card.Body>
      </Card>

      {/* Submit Button */}
      <div className="d-flex justify-content-end">
        <Button variant="primary" onClick={handleSubmitForApproval}>
          Submit for Approval
        </Button>
      </div>
    </Container>
  );
};

export default ReviewOffering;
