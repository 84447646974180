import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { NavLink, useParams } from 'react-router-dom';
import { useError } from '../../contexts/ErrorContext';
import { explorerBaseUrl, maxBlockRange } from '../../utils';
import {
  useBlockNumber,
  useChainId,
  usePublicClient,
  useWriteContract,
} from 'wagmi';
import { decodeEventLog, parseAbiItem } from 'viem';
import { Link } from 'react-router-dom';
import { contracts } from '../../constants';

function Identities() {
  const publicClient = usePublicClient();
  const chainId = useChainId();
  const { auth } = useAuth();
  const { setError } = useError();
  const [identityRegistry, setIdentityRegistry] = useState<string>();
  const [issuersRegistry, setIssuersRegistry] = useState<string>();
  const [topicsRegistry, setTopicsRegistry] = useState<string>();
  const [securityHolders, setSecurityHolders] = useState([]);
  const [identities, setIdentities] = useState<any[]>([]);
  const [allowedContracts, setAllowedContracts] = useState<string[]>([]);
  const [transferAgents, setTransferAgents] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { tokenId } = useParams();
  const { data: blockNumber } = useBlockNumber();
  const [token, setToken] = useState<any>();
  const { writeContractAsync: registerIdentity } = useWriteContract();

  /**
   * Fetch token & security holders
   */
  useEffect(() => {
    (async () => {
      try {
        let url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens?chain_id=${chainId}`;
        let response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        let result = response.data.result;
        const _token = result[0];

        setToken(_token);

        const _identityRegistry: `0x${string}` =
          await publicClient?.readContract({
            address: _token.token_address,
            abi: contracts[chainId!].RestrictedStock.abi,
            functionName: 'identityRegistry',
          })!;

        setIdentityRegistry(_identityRegistry);

        url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/security_holders`;
        response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        result = response.data.result;
        setSecurityHolders(result);

        // Fetch identities
        url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens/${tokenId}/identities?chain_id=${chainId}`;
        response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        result = response.data.result;
        setIdentities(result);
      } catch (error: any) {
        setError(error);
        console.error(error);
      }
    })();
  }, [auth?.token, chainId, setError, tokenId, publicClient]);

  useEffect(() => {
    // look up registered identities
  }, [identities]);

  /**
   * Get issuer and topics registries
   */
  useEffect(() => {
    (async () => {
      if (identityRegistry && blockNumber) {
        const _issuerRegistry: `0x${string}` = await publicClient?.readContract(
          {
            address: identityRegistry as `0x${string}`,
            abi: contracts[chainId!].IdentityRegistry.abi,
            functionName: 'issuersRegistry',
          }
        )!;

        setIssuersRegistry(_issuerRegistry);

        const _topicsRegistry: `0x${string}` = await publicClient?.readContract(
          {
            address: identityRegistry as `0x${string}`,
            abi: contracts[chainId!].IdentityRegistry.abi,
            functionName: 'topicsRegistry',
          }
        )!;

        setTopicsRegistry(_topicsRegistry);

        // Filter for past AllowedContractAdded events
        const fromBlock =
          BigInt(blockNumber!) - BigInt(maxBlockRange[chainId!.toString()]);

        // Define the event ABI
        const eventAbi = {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'contractAddress',
              type: 'address',
            },
          ],
          name: 'AllowedContractAdded',
          type: 'event',
        };

        const logs = await publicClient?.getLogs({
          address: identityRegistry as `0x${string}`,
          event: {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'contractAddress',
                type: 'address',
              },
            ],
            name: 'AllowedContractAdded',
            type: 'event',
          },
          fromBlock,
          toBlock: blockNumber,
        });

        // Extract allowed contract addresses from events and update state
        if (logs?.length) {
          const allowedContractsAddresses = logs?.map(
            (log: any) =>
              (
                decodeEventLog({
                  abi: [eventAbi],
                  data: log.data,
                  topics: log.topics,
                }).args as any
              )?.contractAddress!
          );
          setAllowedContracts(allowedContractsAddresses);
        }
      }
    })();
  }, [identityRegistry, chainId, blockNumber, publicClient]);

  /**
   * @function register
   * @param event
   * @param identityIndex
   * @param identityAddress
   * @param walletAddress
   * @returns
   */
  const register = async (
    event: React.FormEvent,
    identityIndex: number,
    identityAddress: string,
    walletAddress: string
  ) => {
    event.preventDefault();
    if (!chainId || !identityAddress) {
      alert('Please check your wallet connection and input.');
      return;
    }

    try {
      setLoading(true);
      const registryABI = contracts[chainId]?.IdentityRegistry?.abi;

      if (!identityRegistry) {
        alert('Identity Registry contract not found for the current network.');
        return;
      }

      const actionKeys: any = await publicClient?.readContract({
        address: identityAddress as `0x${string}`,
        abi: contracts[chainId!]?.Identity.abi,
        functionName: 'getKeysByPurpose',
        args: [2],
      });
      console.log({ actionKeys });

      const hashedKey = actionKeys ? actionKeys[0] : null;
      console.log({ hashedKey });
      const countryCode = 42;

      // TODO: check if token_identities exists in database already.
      const params = [walletAddress, identityAddress, Number(countryCode)];
      const txHash = await registerIdentity({
        address: identityRegistry as `0x${string}`,
        abi: registryABI,
        functionName: 'registerIdentity',
        args: params,
        gas: BigInt(800_000),
      });

      if (txHash) {
        alert('Identity registered successfully.');

        // Update the identities array state to reflect registration
        setIdentities((prevIdentities: any) =>
          prevIdentities.map((id: any, idx: number) => {
            if (idx === identityIndex) {
              return { ...id, is_registered: true };
            }
            return id;
          })
        );
      }
    } catch (error) {
      console.error('Registration failed:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @function fetchTransferAgents
   * @returns
   */
  const fetchTransferAgents = async () => {
    // Implement fetching transfer agents from the contract
    return []; // Return an array of transfer agent addresses
  };

  return (
    <Container>
      {/* Breadcrumb */}
      <div className="border-bottom py-3 mb-4">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink to="/equity">Equity</NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/equity/${tokenId}`}>{token?.token_name}</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Entitlements
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 className="mb-0">Entitlements Dashboard</h4>
      </div>
      <div>
        <h5>Important Contracts</h5>
        <small className="text-muted">
          The shareholder registry contract maintains a list of authorized
          shareholders. The topics and attestor registries contain KYC/KYB and
          AML requirements.
        </small>
      </div>
      <div className="d-inline-block m-1 border rounded p-2 d-inline card bg-light text-dark">
        Shareholder Registry:{' '}
        <Link
          target="_blank"
          to={`${explorerBaseUrl[chainId!]}/address/${identityRegistry}`}
        >
          {identityRegistry}&nbsp;
          <i className="fa fa-external-link" />
        </Link>
      </div>
      <div className="d-inline-block m-1 border rounded p-2 d-inline card bg-light text-dark">
        Attestors Registry: {issuersRegistry}
      </div>
      <div className="d-inline-block m-1 border rounded p-2 d-inline card bg-light text-dark">
        Topics Registry: {topicsRegistry}
      </div>
      <hr />
      <div className="d-flex justify-content-between align-items-center mt-4">
        <div>
          <h5>Authorized Shareholders</h5>
          <small className="text-muted">
            This shareholder registry is an allowlist for your equity token.
          </small>
        </div>
        <NavLink to="register-identity" className="btn btn-primary">
          Add User
        </NavLink>
      </div>
      <Table hover responsive className="mt-3">
        <thead>
          <tr>
            <th>Identity ID</th>
            <th>Name</th>
            <th>Wallet Address</th>
            <th>Identity Address</th>
          </tr>
        </thead>
        <tbody>
          {identities.map((identity: any, index: number) => (
            <tr key={index}>
              <td>{identity?.identity_id}</td>
              <td>
                <NavLink to={identity?.identity_id}>{identity?.name}</NavLink>
              </td>
              <td>{identity?.wallet_address}</td>
              <td>{identity?.identity_address}</td>
            </tr>
          ))}
          {identities.length === 0 && (
            <tr>
              <td colSpan={12} className="text-center py-5">
                No registered identities
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <hr />
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Smart Contracts</h5>
          <NavLink to="register-contract" className="btn btn-primary">
            Add Contract
          </NavLink>
        </div>
        <p>
          <small className="text-muted">
            This allowlist contains smart contracts that may hold or transfer
            shares on behalf of shareholders.
          </small>
        </p>
        {allowedContracts.length > 0 ? (
          <ul>
            {allowedContracts.map((address, index) => (
              <li key={index}>{address}</li>
            ))}
          </ul>
        ) : (
          <p>No allowed contracts found.</p>
        )}
      </div>
      <hr />
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Transfer Agents</h5>
          <NavLink to="register-transfer-agent" className="btn btn-primary">
            Add Agent
          </NavLink>
        </div>
        <p>
          <small className="text-muted">
            This section lists transfer agents who are authorized to manage and
            transfer shares on behalf of shareholders.
          </small>
        </p>
        {transferAgents.length > 0 ? (
          <ul>
            {transferAgents.map((address, index) => (
              <li key={index}>{address}</li>
            ))}
          </ul>
        ) : (
          <p>No transfer agents found.</p>
        )}
      </div>
    </Container>
  );
}

export default Identities;
