import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { capitalizeString } from '../../utils';
import { useAuth } from '../../contexts/AuthContext';
import { NavLink } from 'react-router-dom';
import Dashboard from './Dashboard';
import { useChainId } from 'wagmi';
import emptyIcon from './empty.png';

export default function Assets() {
  const { auth } = useAuth();
  const chainId = useChainId();
  const [tokens, setTokens] = useState<any>([]);

  /**
   * Fetch business entity tokens
   */
  React.useEffect(() => {
    (async () => {
      try {
        if (chainId) {
          const url = `${process.env.REACT_APP_API_URL}/v1/business_entities/me/tokens?chain_id=${chainId}`;
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          });
          const result = response.data.result;
          setTokens(result);
        }
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [auth?.token]);

  return (
    <>
      <Container className="py-5" fluid>
        {tokens.length ? (
          <>
            <Row>
              <Col md={{ span: 10, offset: 1 }} className="mb-3">
                {tokens.map((token: any, index: number) => (
                  <NavLink
                    to={`/equity/${token.token_address}`}
                    className="text-decoration-none"
                    key={index}
                  >
                    <div className="d-flex justify-content-between align-items-center border rounded shadow-sm p-4 my-3">
                      <div className="text-truncate">
                        <span>{token.token_issuer}</span>&nbsp;
                        <span className="text-muted">
                          {capitalizeString(
                            token.asset_subtype.replace('_', ' ')
                          )}
                        </span>
                        <br />
                      </div>
                      {/* TODO: Link to explorer */}
                      <div className="d-none d-md-block">
                        {token.token_address}
                      </div>
                    </div>
                  </NavLink>
                ))}
              </Col>
            </Row>
            <Dashboard />
          </>
        ) : (
          <Row className="h-100">
            <Col md={{ span: 8, offset: 2 }} className="mb-5">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Equity Management Dashboard</h4>
                <NavLink to="/equity/create" className="btn btn-primary">
                  New Token
                </NavLink>
              </div>
              {tokens.length === 0 && (
                <div className="justify-content-center align-items-center d-flex mt-5">
                  <img src={emptyIcon} className="img-fluid" />
                </div>
              )}
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
