import SubscriptionManager from '@capsign/contracts/artifacts/contracts/platform/SubscriptionManager.sol/SubscriptionManager.json';
import Auction from '@capsign/contracts/artifacts/contracts/markets/Auction.sol/Auction.json';
import Identity from '@capsign/contracts/artifacts/contracts/identity/Identity.sol/Identity.json';
import IdentityFactory from '@capsign/contracts/artifacts/contracts/identity/factory/IdFactory.sol/IdFactory.json';
import IdentityRegistry from '@capsign/contracts/artifacts/contracts/securities/registry/implementation/IdentityRegistry.sol/IdentityRegistry.json';
import ImplementationAuthority from '@capsign/contracts/artifacts/contracts/identity/proxy/ImplementationAuthority.sol/ImplementationAuthority.json';
import ModularCompliance from '@capsign/contracts/artifacts/contracts/securities/compliance/modular/ModularCompliance.sol/ModularCompliance.json';
import VestingModule from '@capsign/contracts/artifacts/contracts/securities/compliance/modular/modules/VestingModule.sol/VestingModule.json';
import RestrictedStock from '@capsign/contracts/artifacts/contracts/securities/token/RestrictedStock.sol/RestrictedStock.json';
import TREXFactory from '@capsign/contracts/artifacts/contracts/securities/factory/TREXFactory.sol/TREXFactory.json';
import SAFE from '@capsign/contracts/artifacts/contracts/platform/SAFE.sol/SAFE.json';
import USD from '@capsign/contracts/artifacts/contracts/platform/USD.sol/USD.json';
import RegCF from '@capsign/contracts/artifacts/contracts/offerings/RegCF.sol/RegCF.json';
import { ContractInfo } from './types';
import { ContractInterface } from 'ethers';
import config from '../package.json';
export const version = config.version;

// Load the JSON data
import localhost from './addresses.localhost.json';
import xrpl_devnet from './addresses.xrpl_devnet.json';
import base_mainnet from './addresses.base_mainnet.json';
import base_sepolia from './addresses.base_sepolia.json';
import { Abi } from 'viem';

export const contracts: {
  [key: number]: { [name: string]: ContractInfo };
} = {
  130: {
    IdentityImplementationAuthority: {
      address: '',
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: '',
      abi: Auction.abi as Abi & ContractInterface,
    },
    SubscriptionManager: {
      address: '0x0BE76cc9b7c4de59C89752E3fCB63AB4C2d96CC7',
      abi: SubscriptionManager.abi as Abi & ContractInterface,
    },
    USD: {
      address: '0x09CBB7323055E2559CbAAe8D4496e1a7D47e126a',
      abi: RestrictedStock.abi as Abi & ContractInterface,
    },
    CAP: {
      address: '0xF410B1684B2D015dC688d60ade0a1c10dEeab967',
      abi: RestrictedStock.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
  8453: {
    Identity: {
      address: base_mainnet.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrpl_devnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: base_mainnet.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: base_mainnet.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: base_mainnet.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: base_mainnet.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    VestingModule: {
      address: '',
      abi: VestingModule.abi as Abi & ContractInterface,
    },
    SubscriptionManager: {
      address: base_mainnet.suite.subscriptionManager,
      abi: SubscriptionManager.abi as Abi & ContractInterface,
    },
    TREXFactory: {
      address: base_mainnet.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    RestrictedStock: {
      address: base_mainnet.implementations.tokenImplementation,
      abi: RestrictedStock.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: base_mainnet.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
  31337: {
    Identity: {
      address: localhost.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrpl_devnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: localhost.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: localhost.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: localhost.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: localhost.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    SubscriptionManager: {
      address: localhost.suite.subscriptionManager,
      abi: SubscriptionManager.abi as Abi & ContractInterface,
    },
    TREXFactory: {
      address: localhost.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    RestrictedStock: {
      address: localhost.implementations.tokenImplementation,
      abi: RestrictedStock.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: localhost.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
  84532: {
    Identity: {
      address: base_sepolia.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrpl_devnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: base_sepolia.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: base_sepolia.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    Auction: {
      address: base_sepolia.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: base_sepolia.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    VestingModule: {
      address: '0xC15b6e5dA7eD5E4011fef4773DB1Fe3dCcB857FE',
      abi: VestingModule.abi as Abi & ContractInterface,
    },
    SubscriptionManager: {
      address: base_sepolia.suite.subscriptionManager,
      abi: SubscriptionManager.abi as any,
    },
    TREXFactory: {
      address: base_sepolia.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    RestrictedStock: {
      address: base_sepolia.implementations.tokenImplementation,
      abi: RestrictedStock.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: base_sepolia.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    USDC: {
      address: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
      abi: [
        {
          inputs: [
            { internalType: 'string', name: '_name', type: 'string' },
            { internalType: 'string', name: '_symbol', type: 'string' },
            { internalType: 'uint8', name: '_decimals', type: 'uint8' },
            { internalType: 'address', name: '_underlying', type: 'address' },
            { internalType: 'address', name: '_vault', type: 'address' },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'auth',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'timestamp',
              type: 'uint256',
            },
          ],
          name: 'LogAddAuth',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'oldOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'effectiveHeight',
              type: 'uint256',
            },
          ],
          name: 'LogChangeMPCOwner',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'oldVault',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newVault',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'effectiveTime',
              type: 'uint256',
            },
          ],
          name: 'LogChangeVault',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'bytes32',
              name: 'txhash',
              type: 'bytes32',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'LogSwapin',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'bindaddr',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'LogSwapout',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          inputs: [],
          name: 'DOMAIN_SEPARATOR',
          outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'PERMIT_TYPEHASH',
          outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'bytes32', name: 'txhash', type: 'bytes32' },
            { internalType: 'address', name: 'account', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          name: 'Swapin',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'address', name: 'bindaddr', type: 'address' },
          ],
          name: 'Swapout',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'TRANSFER_TYPEHASH',
          outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: '', type: 'address' },
            { internalType: 'address', name: '', type: 'address' },
          ],
          name: 'allowance',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'applyMinter',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'applyVault',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
          ],
          name: 'approve',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
          ],
          name: 'approveAndCall',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{ internalType: 'address', name: '', type: 'address' }],
          name: 'balanceOf',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          name: 'burn',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'newVault', type: 'address' },
          ],
          name: 'changeMPCOwner',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'newVault', type: 'address' },
          ],
          name: 'changeVault',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'delay',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'delayDelay',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'delayMinter',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'delayVault',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
          ],
          name: 'deposit',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          name: 'deposit',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'deposit',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
          ],
          name: 'depositVault',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'target', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
            { internalType: 'uint256', name: 'deadline', type: 'uint256' },
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
            { internalType: 'address', name: 'to', type: 'address' },
          ],
          name: 'depositWithPermit',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'target', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
            { internalType: 'uint256', name: 'deadline', type: 'uint256' },
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
            { internalType: 'address', name: 'to', type: 'address' },
          ],
          name: 'depositWithTransferPermit',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getAllMinters',
          outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: '_vault', type: 'address' },
          ],
          name: 'initVault',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{ internalType: 'address', name: '', type: 'address' }],
          name: 'isMinter',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          name: 'mint',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          name: 'minters',
          outputs: [{ internalType: 'address', name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'mpc',
          outputs: [{ internalType: 'address', name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [{ internalType: 'string', name: '', type: 'string' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [{ internalType: 'address', name: '', type: 'address' }],
          name: 'nonces',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [{ internalType: 'address', name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingDelay',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingMinter',
          outputs: [{ internalType: 'address', name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'pendingVault',
          outputs: [{ internalType: 'address', name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'target', type: 'address' },
            { internalType: 'address', name: 'spender', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
            { internalType: 'uint256', name: 'deadline', type: 'uint256' },
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          name: 'permit',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{ internalType: 'address', name: '_auth', type: 'address' }],
          name: 'revokeMinter',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{ internalType: 'address', name: '_auth', type: 'address' }],
          name: 'setMinter',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: '_vault', type: 'address' },
          ],
          name: 'setVault',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{ internalType: 'bool', name: 'enabled', type: 'bool' }],
          name: 'setVaultOnly',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [{ internalType: 'string', name: '', type: 'string' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'totalSupply',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
          ],
          name: 'transfer',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
            { internalType: 'bytes', name: 'data', type: 'bytes' },
          ],
          name: 'transferAndCall',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
          ],
          name: 'transferFrom',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'target', type: 'address' },
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
            { internalType: 'uint256', name: 'deadline', type: 'uint256' },
            { internalType: 'uint8', name: 'v', type: 'uint8' },
            { internalType: 'bytes32', name: 'r', type: 'bytes32' },
            { internalType: 'bytes32', name: 's', type: 'bytes32' },
          ],
          name: 'transferWithPermit',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'underlying',
          outputs: [{ internalType: 'address', name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'vault',
          outputs: [{ internalType: 'address', name: '', type: 'address' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
          ],
          name: 'withdraw',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
          ],
          name: 'withdraw',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'withdraw',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            { internalType: 'address', name: 'from', type: 'address' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'address', name: 'to', type: 'address' },
          ],
          name: 'withdrawVault',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
  1440002: {
    Identity: {
      address: xrpl_devnet.implementations.identityImplementation,
      abi: Identity.abi as Abi & ContractInterface,
    },
    IdentityFactory: {
      address: xrpl_devnet.factories.identityFactory,
      abi: IdentityFactory.abi as Abi & ContractInterface,
    },
    IdentityImplementationAuthority: {
      address: xrpl_devnet.authorities.identityImplementationAuthority,
      abi: ImplementationAuthority.abi as Abi & ContractInterface,
    },
    IdentityRegistry: {
      address: xrpl_devnet.suite.identityRegistry,
      abi: IdentityRegistry.abi as Abi & ContractInterface,
    },
    Auction: {
      address: xrpl_devnet.suite.auction,
      abi: Auction.abi as Abi & ContractInterface,
    },
    ModularCompliance: {
      address: localhost.implementations.modularComplianceImplementation,
      abi: ModularCompliance.abi as Abi & ContractInterface,
    },
    SubscriptionManager: {
      address: xrpl_devnet.suite.subscriptionManager,
      abi: SubscriptionManager.abi as Abi & ContractInterface,
    },
    TREXFactory: {
      address: xrpl_devnet.factories.trexFactory,
      abi: TREXFactory.abi as Abi & ContractInterface,
    },
    RestrictedStock: {
      address: xrpl_devnet.implementations.tokenImplementation,
      abi: RestrictedStock.abi as Abi & ContractInterface,
    },
    SAFE: {
      address: '',
      abi: SAFE.abi as Abi & ContractInterface,
      bytecode: SAFE.bytecode,
    },
    USD: {
      address: xrpl_devnet.tokens.usd,
      abi: USD.abi as Abi & ContractInterface,
    },
    RegCF: {
      address: '',
      abi: RegCF.abi as Abi & ContractInterface,
    },
  },
};

// NumbroJS
export const formatOptions = {
  thousandSeparated: true,
  optionalMantissa: true,
  trimMantissa: false,
  mantissa: 2,
};
