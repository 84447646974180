import React, { useState, useEffect } from 'react';
import {
  useAccount,
  usePublicClient,
  useWalletClient,
  useChainId,
} from 'wagmi';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import SAFE from '@capsign/contracts/artifacts/contracts/platform/SAFE.sol/SAFE.json';
import { useOffering } from '../../../contexts/OfferingContext';
import { useError } from '../../../contexts/ErrorContext';
import TokenInputDropdown from './TokenInputDropdown';

// Import images
import usdcImage from '../../../assets/images/usdc.png';
import ethImage from '../../../assets/images/eth.png';
import { decodeEventLog } from 'viem';

const CreateOfferingSafeTerms: React.FC = () => {
  const { address: account } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { auth } = useAuth();
  const { offeringId } = useParams();
  const chainId = useChainId();
  const navigate = useNavigate();
  const { offering, fetchOffering } = useOffering();
  const { setError } = useError();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<any>({
    discount: 0,
    valuationCap: 0,
    mostFavoredNation: false,
    investmentToken: '',
    minimumInvestment: '',
    investmentDeadline: '',
    boardApprovalDate: '',
    stockholderApprovalDate: '',
    considerationText: '',
    proRataPercentage: 0,
    seniority: 1,
    uri: '',
  });

  const [txHash, setTxHash] = useState<`0x${string}` | undefined>();
  const [contractAddress, setContractAddress] = useState<
    `0x${string}` | undefined
  >();

  const handleFormChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (!walletClient) {
        throw new Error('No wallet client available');
      }

      // Get the ABI and bytecode for SAFE.sol
      const contractAbi = SAFE.abi;
      const contractBytecode = SAFE.bytecode as `0x${string}`;

      // Prepare constructor arguments if any (assuming constructor(string memory uri))
      const constructorArgs = [formData.uri];

      // Encode deployment transaction
      const deployTx = await walletClient.deployContract({
        abi: contractAbi,
        bytecode: contractBytecode,
        args: constructorArgs,
      });

      // Wait for the transaction to be mined
      setTxHash(deployTx);
      const receipt = await publicClient?.waitForTransactionReceipt({
        hash: deployTx,
      });

      // Get the contract address
      const deployedAddress = receipt?.contractAddress;
      setContractAddress(deployedAddress as `0x${string}`);

      // Now call createSafeTerms
      const safeContract = {
        address: deployedAddress as `0x${string}`,
        abi: contractAbi,
      };

      // Prepare the arguments for createSafeTerms
      const createSafeTermsArgs = [
        formData.discount,
        formData.valuationCap,
        formData.mostFavoredNation,
        formData.investmentToken ||
          '0x0000000000000000000000000000000000000000', // Address(0) for Ether
        Math.floor(new Date(formData.boardApprovalDate).getTime() / 1000), // Convert to timestamp
        formData.stockholderApprovalDate
          ? Math.floor(
              new Date(formData.stockholderApprovalDate).getTime() / 1000
            )
          : 0,
        formData.considerationText,
        [], // securityLawExemptions, assuming empty for now
        { value: formData.proRataPercentage, unit: '%' }, // Assuming Numeric struct { value, unit }
        formData.seniority,
        formData.minimumInvestment,
        Math.floor(new Date(formData.investmentDeadline).getTime() / 1000), // Convert to timestamp
        formData.uri,
      ];

      // Call createSafeTerms
      const result = await publicClient?.simulateContract({
        ...safeContract,
        functionName: 'createSafeTerms',
        args: createSafeTermsArgs,
        account,
      });

      const createTermsTxHash = await walletClient.writeContract({
        ...safeContract,
        functionName: 'createSafeTerms',
        args: createSafeTermsArgs,
        account,
      });
      const createTermsReceipt = await publicClient?.waitForTransactionReceipt({
        hash: createTermsTxHash,
      });

      const event = decodeEventLog({
        abi: contractAbi,
        eventName: 'SafeTermsCreated',
        data: createTermsReceipt?.logs[0].data,
        topics: createTermsReceipt?.logs[0].topics as any,
      });
      console.log('event', event);
      const tokenId = (event?.args as any)?.tokenId.toNumber();
      console.log('tokenId', tokenId);

      // Create the safe terms on the backend
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}/safe_terms`,
        {
          discount: formData.discount,
          valuation_cap: formData.valuationCap,
          most_favored_nation: formData.mostFavoredNation,
          investment_token: formData.investmentToken,
          minimum_investment: formData.minimumInvestment,
          board_approval_date: formData.boardApprovalDate,
          stockholder_approval_date: formData.stockholderApprovalDate,
          consideration_text: formData.considerationText,
          pro_rata_percentage: formData.proRataPercentage,
          investment_deadline: formData.investmentDeadline,
          seniority: formData.seniority,
          uri: formData.uri,
          tx_hash: deployTx,
          token_id: tokenId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      // Update the backend with contract address and any other necessary data
      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/offerings/${offeringId}`,
        {
          contract_address: deployedAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        }
      );

      // Navigate to next page or show confirmation
      navigate(`/offerings/${offeringId}/review`);
    } catch (error: any) {
      console.error('Error deploying SAFE contract:', error);
      setError(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (offeringId && !offering) fetchOffering(offeringId);
  }, [offeringId, fetchOffering]);

  const recommendedTokens = [
    {
      address: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
      name: 'USD Coin',
      imageUrl: usdcImage,
    },
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Ethereum',
      imageUrl: ethImage,
    },
  ];

  return (
    <Container className="my-5">
      <div className="row">
        <div className="col-8 offset-2">
          <h2>SAFE Offering Terms</h2>
          <Form onSubmit={handleSubmit}>
            {loading && <p>Deploying SAFE contract...</p>}
            {txHash && <p>Transaction hash: {txHash}</p>}
            {contractAddress && <p>Contract address: {contractAddress}</p>}
            {/* Discount */}
            <Form.Group as={Row} className="mb-3" controlId="formDiscount">
              <Form.Label column sm={2}>
                Discount (%)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="discount"
                  value={formData.discount}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* Valuation Cap */}
            <Form.Group as={Row} className="mb-3" controlId="formValuationCap">
              <Form.Label column sm={2}>
                Valuation Cap
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="valuationCap"
                  value={formData.valuationCap}
                  onChange={handleFormChange}
                  required
                />
                <Form.Text className="text-muted">
                  Enter 0 if there is no valuation cap.
                </Form.Text>
              </Col>
            </Form.Group>

            {/* Most Favored Nation */}
            <Form.Group as={Row} className="mb-3" controlId="formMFN">
              <Form.Label column sm={2}>
                Most Favored Nation
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  type="checkbox"
                  name="mostFavoredNation"
                  checked={formData.mostFavoredNation}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Investment Token */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formInvestmentToken"
            >
              <Form.Label column sm={2}>
                Investment Token Address
              </Form.Label>
              <Col sm={10}>
                <TokenInputDropdown
                  recommendedTokens={recommendedTokens}
                  value={formData.investmentToken}
                  onChange={(value) =>
                    setFormData({ ...formData, investmentToken: value })
                  }
                />
              </Col>
            </Form.Group>

            {/* Minimum Investment */}
            <Form.Group as={Row} className="mb-3" controlId="minimumInvestment">
              <Form.Label column sm={2}>
                Minimum Investment Amount
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="minimumInvestment"
                  value={formData.minimumInvestment}
                  onChange={handleFormChange}
                  placeholder="Enter minimum investment amount"
                />
              </Col>
            </Form.Group>

            {/* Investment Deadline */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formInvestmentDeadline"
            >
              <Form.Label column sm={2}>
                Investment Deadline
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="date"
                  name="investmentDeadline"
                  value={formData.investmentDeadline}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* Board Approval Date */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formBoardApprovalDate"
            >
              <Form.Label column sm={2}>
                Board Approval Date
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="date"
                  name="boardApprovalDate"
                  value={formData.boardApprovalDate}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* Stockholder Approval Date */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formStockholderApprovalDate"
            >
              <Form.Label column sm={2}>
                Stockholder Approval Date
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="date"
                  name="stockholderApprovalDate"
                  value={formData.stockholderApprovalDate}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Consideration Text */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formConsiderationText"
            >
              <Form.Label column sm={2}>
                Consideration Text
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  name="considerationText"
                  rows={3}
                  placeholder="Describe the consideration for the SAFE"
                  value={formData.considerationText}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Pro Rata Percentage */}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formProRataPercentage"
            >
              <Form.Label column sm={2}>
                Pro Rata Rights (%)
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="proRataPercentage"
                  value={formData.proRataPercentage}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            {/* Seniority */}
            <Form.Group as={Row} className="mb-3" controlId="formSeniority">
              <Form.Label column sm={2}>
                Seniority
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="number"
                  name="seniority"
                  value={formData.seniority}
                  onChange={handleFormChange}
                  required
                />
              </Col>
            </Form.Group>

            {/* URI */}
            <Form.Group as={Row} className="mb-3" controlId="formURI">
              <Form.Label column sm={2}>
                Metadata URI
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="uri"
                  placeholder="Enter metadata URI"
                  value={formData.uri}
                  onChange={handleFormChange}
                />
              </Col>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'Deploying...' : 'Save and Continue'}
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default CreateOfferingSafeTerms;
