import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';

type EntityContextType = {
  entity: {
    entity_id?: string;
    entity_type?: string;
    activity_type?: string;
  };
  setEntity: (entity: object | null) => void;
};

const EntityContext = createContext<EntityContextType | undefined>(undefined);

export const useEntity = () => {
  const context = useContext(EntityContext);
  if (context === undefined) {
    throw new Error('useEntity must be used within an EntityProvider');
  }
  return context;
};

interface EntityProviderProps {
  children: ReactNode;
}

export const EntityProvider: React.FC<EntityProviderProps> = ({ children }) => {
  const { auth } = useAuth();
  const [entity, setEntity] = useState<object>();

  React.useEffect(() => {
    (async () => {
      try {
        if (auth?.token) {
          const response = await axios(
            `${process.env.REACT_APP_API_URL}/v1/business_entities/me`,
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'X-Account-Id': auth.user.account_id,
              },
            }
          );
          setEntity(response.data.result);
        }
      } catch (error: any) {
        console.log(error);
      }
    })();
  }, [auth]);

  const value = { entity, setEntity };

  return (
    <EntityContext.Provider value={value as any}>
      {children}
    </EntityContext.Provider>
  );
};
