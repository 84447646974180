import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import { contracts, formatOptions } from '../../constants';
import { useParams, NavLink } from 'react-router-dom';
import { useError } from '../../contexts/ErrorContext';
import {
  useReadContract,
  useWriteContract,
  useWaitForTransactionReceipt,
  useChainId,
  usePublicClient,
  useAccount,
} from 'wagmi';
import { Abi, decodeEventLog, zeroAddress } from 'viem';
import { InputGroup } from 'react-bootstrap';

enum Operation {
  Issue = 1,
  Cancel = 2,
}

const ManageCertificateForm = ({
  showModal,
  setShowModal,
  identity,
}: {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  identity: any;
}) => {
  const { auth } = useAuth();
  const { setError } = useError();
  const { tokenId } = useParams();
  const [shareClass, setShareClass] = useState<any>();
  const [capTable, setCapTable] = useState<any>();
  const [shareClasses, setShareClasses] = useState<any[]>([]);
  const [securityLegend, setSecurityLegend] = useState<string>(
    'THE SECURITIES REPRESENTED BY THIS CERTIFICATE HAVE NOT BEEN REGISTERED UNDER THE SECURITIES ACT OF 1933, AS AMENDED (THE "ACT"), OR ANY STATE SECURITIES LAWS. THESE SECURITIES MAY NOT BE SOLD, TRANSFERRED, PLEDGED, OR OTHERWISE DISPOSED OF IN THE ABSENCE OF AN EFFECTIVE REGISTRATION STATEMENT UNDER THE ACT AND ANY APPLICABLE STATE SECURITIES LAWS OR AN OPINION OF COUNSEL SATISFACTORY TO THE COMPANY THAT SUCH REGISTRATION IS NOT REQUIRED.'
  );
  const [federalExemption, setFederalExemption] = useState<string>('');
  const [securityId, setSecurityId] = useState<any>('');
  const [operation, setOperation] = useState(Operation.Issue);
  const [tokenAmount, setTokenAmount] = useState('');
  const [cashPaid, setCashPaid] = useState('');
  const [costBasis, setCostBasis] = useState('');
  const [pricePerShare, setPricePerShare] = useState('');
  const [vestingName, setVestingName] = useState('');
  const [vestingDescription, setVestingDescription] = useState('');
  const [allocationType, setAllocationType] = useState('CUMULATIVE_ROUNDING');
  const [vestingConditions, setVestingConditions] = useState<any>([]);
  const [vestingAmount, setVestingAmount] = useState(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [hasVesting, setHasVesting] = useState(false);
  const [loading, setLoading] = useState(false);
  const chainId = useChainId();
  const publicClient = usePublicClient();

  const tokenContract = contracts[chainId!]?.RestrictedStock;
  const identityRegistryContract = contracts[chainId!]?.IdentityRegistry;

  /**
   * Fetch share classes
   */
  useEffect(() => {
    (async () => {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/v1/business_entities/me/equity_token?chain_id=${chainId}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'X-Account-Id': auth?.user.account_id,
          },
        });
        setShareClasses(response.data.result.share_classes);
        setShareClass(response.data.result.share_classes[0]);
        setCapTable(response.data.result.cap_table);
      } catch (error) {
        setError(error);
      }
    })();
  }, [auth?.token, chainId]);

  const { data: identityRegistryAddress } = useReadContract({
    address: tokenId as `0x${string}`,
    abi: tokenContract.abi as Abi,
    functionName: 'identityRegistry',
  });

  const { data: isVerified } = useReadContract({
    address: identityRegistryAddress as `0x${string}`,
    abi: identityRegistryContract.abi as Abi,
    functionName: 'isVerified',
    args: [identity?.wallet_address],
  });

  const { writeContractAsync: issueTokens } = useWriteContract();
  const { writeContractAsync: cancelTokens } = useWriteContract();
  const { data: issueReceipt, isLoading: isIssueLoading } =
    useWaitForTransactionReceipt();
  const { data: cancelReceipt, isLoading: isCancelLoading } =
    useWaitForTransactionReceipt();

  // Calculate price per share whenever tokenAmount or cashPaid changes
  useEffect(() => {
    const amount = parseFloat(tokenAmount);
    const cash = parseFloat(cashPaid);
    if (amount > 0 && cash > 0) {
      const calculatedPrice = cash / amount;
      setPricePerShare(calculatedPrice.toString());
    } else {
      setPricePerShare('');
    }
  }, [tokenAmount, cashPaid]);

  /**
   * @function handleOperation
   * @param event
   */
  const handleOperation = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (operation === Operation.Issue) await issue();
    if (operation === Operation.Cancel) await cancel();
  };

  const handleAddCondition = () => {
    setVestingConditions([
      ...vestingConditions,
      {
        id: '',
        description: '',
        portionNumerator: 25,
        portionDenominator: 100,
        quantity: 0,
        triggerType: 'date', // default to date, can be changed
        triggerDate: Math.floor(Date.now() / 1000), // default to current time
        relativeToConditionId: '',
        nextConditionIds: [],
      },
    ]);
  };

  const handleConditionChange = (index: number, field: string, value: any) => {
    const updatedConditions = [...vestingConditions];
    updatedConditions[index][field] = value;
    setVestingConditions(updatedConditions);
  };

  const handleRemoveCondition = (index: number) => {
    const updatedConditions = vestingConditions.filter(
      (_: any, i: number) => i !== index
    );
    setVestingConditions(updatedConditions);
  };

  /**
   * @function issue
   * @returns
   */
  const issue = async () => {
    setLoading(true);
    try {
      if (!isVerified) {
        alert('Please register your identity with this token.');
        setLoading(false);
        return;
      }

      const uri = 'https://api.capsign.com/v1/metadata/test.json';
      let vestingTerms = {
        name: 'No Vesting',
        description: 'No Vesting',
        allocationType: 'CUMULATIVE_ROUNDING',
        conditions: [],
      };
      let startTime = startDate ? Math.floor(startDate!.getTime() / 1000) : 0;
      let _vestingAmount = tokenAmount;

      if (hasVesting) {
        startTime = Math.floor(startDate!.getTime() / 1000);
        vestingTerms = {
          name: vestingName,
          description: vestingDescription,
          allocationType,
          conditions: vestingConditions.map((condition: any) => ({
            ...condition,
            triggerDate:
              condition.triggerType === 'date'
                ? Math.floor(new Date(condition.triggerDate).getTime() / 1000)
                : undefined,
          })),
        };
      }

      const params = [
        identity.wallet_address,
        shareClass.class_hex,
        tokenAmount,
        uri,
        vestingAmount || _vestingAmount,
        startTime,
        vestingTerms,
      ];

      const txHash = await issueTokens({
        args: params,
        address: tokenId as `0x${string}`,
        abi: tokenContract.abi as Abi,
        functionName: 'issue',
        gas: BigInt(3_000_000),
      });

      const issueReceipt = await publicClient?.waitForTransactionReceipt({
        hash: txHash,
      });

      // Get Transfer event arg here, save it in database below
      const transferEventAbiItem: any = tokenContract.abi.find(
        (item: any) => item.name === 'Transfer'
      )!;
      const log = issueReceipt?.logs[0];

      // Extract allowed contract addresses from events and update state
      let _securityId = null;
      if (log) {
        // Define the event ABI
        const eventAbi = {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'bytes32',
              name: 'securityId',
              type: 'bytes32',
            },
            {
              indexed: false,
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'bytes32[]',
              name: 'newIds',
              type: 'bytes32[]',
            },
          ],
          name: 'Transfer',
          type: 'event',
        };
        const decodedLog = decodeEventLog({
          abi: [eventAbi],
          data: log.data,
          topics: log.topics,
        });
        _securityId = (decodedLog?.args! as any).securityId;
        setSecurityId(_securityId);
      }

      if (!isIssueLoading) {
        // Create certificate
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/cap_tables/${capTable.cap_table_id}/certificates`,
          {
            share_class: shareClass.class_id,
            quantity: tokenAmount,
            available_quantity: tokenAmount,
            federal_exemption: federalExemption,
            legend: securityLegend,
            account_id: identity.account_id,
            tx_hash: txHash,
            security_id: _securityId,
            price_per_share: pricePerShare,
            cash_paid: cashPaid,
            cost_basis: costBasis,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'X-Account-Id': auth?.user.account_id,
            },
          }
        );

        setTokenAmount('');
        setPricePerShare('');
        setShowModal(false);
        setLoading(false);
        alert(`Transaction success! Tx hash: ${issueReceipt?.transactionHash}`);
      }
    } catch (e) {
      setError(e);
      console.error(e);
      setLoading(false);
    }
  };

  /**
   * @function cancel
   * @returns
   */
  const cancel = async () => {
    setLoading(true);
    try {
      if (!isVerified) {
        alert('Please register your identity with this token.');
        setLoading(false);
        return;
      }

      const params = [identity.wallet_address, securityId, tokenAmount];

      const cancelReceipt = await cancelTokens({
        address: tokenId as `0x${string}`,
        abi: tokenContract.abi as Abi,
        args: params,
        functionName: 'cancel',
      });

      if (!isCancelLoading) {
        setTokenAmount('');
        setShowModal(false);
        setLoading(false);
        alert(`Transaction success! Tx hash: ${cancelReceipt}`);
      }
    } catch (e) {
      setError(e);
      console.error(e);
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleOperation}>
      <h5>Certificate Details</h5>
      <Form.Group className="mb-3">
        <Form.Label>Operation</Form.Label>
        <div>
          <Form.Check
            type="radio"
            label="Issue"
            name="operation"
            value={Operation.Issue}
            defaultChecked
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOperation(Number(e.target.value))
            }
            required
          />
          <Form.Check
            type="radio"
            label="Cancel"
            name="operation"
            value={Operation.Cancel}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOperation(Number(e.target.value))
            }
            required
          />
        </div>
      </Form.Group>
      {operation === Operation.Issue && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Share Class</Form.Label>
            <Form.Select
              onChange={(e: any) => setShareClass(shareClasses[e.target.value])}
              required
            >
              {shareClasses?.map((_class: any, index: number) => {
                return (
                  <option key={index} value={index}>
                    {`${_class.class_type} ${_class.class_name}`}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Federal Exemption</Form.Label>
            <Form.Select
              name="federalExemption"
              value={federalExemption}
              onChange={(e) => setFederalExemption(e.target.value)}
            >
              <option value="Rule 701">Rule 701</option>
              <option value="Section 4(a)(2)">Section 4(a)(2)</option>
              <option value="Section 4(A)(1-1/2)">Section 4(A)(1-1/2)</option>
              <option value="Section 4(a)(7)">Section 4(a)(7)</option>
              <option value="Rule 144">Rule 144</option>
              <option value="Reg D - 506(b)">Reg D - 506(b)</option>
              <option value="Reg D - 506(c)">Reg D - 506(c)</option>
              <option value="Reg D - 506">Reg D - 506</option>
              <option value="Reg D - 505">Reg D - 505</option>
              <option value="Reg D - 504">Reg D - 504</option>
              <option value="Reg S">Reg S</option>
              <option value="Reg A (tier 1)">Reg A (tier 1)</option>
              <option value="Reg A (tier 2)">Reg A (tier 2)</option>
              <option value="Reg CF">Reg CF</option>
              <option value="Non-U.S.">Non-U.S.</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Security Legend</Form.Label>
            <Form.Control
              as="textarea"
              rows={11}
              placeholder="Enter the security legend"
              name="securityLegend"
              value={securityLegend}
              onChange={(e) => setSecurityLegend(e.target.value)}
            />
          </Form.Group>
        </>
      )}
      <Form.Group className="mb-3">
        <Form.Label>Security ID</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter security ID"
          onChange={(e) => setSecurityId(e.target.value)}
          required={operation === Operation.Cancel}
        />
        <small className="text-muted">
          {operation === Operation.Issue ? (
            <>
              (Optional) An associated security ID given by an external system.
            </>
          ) : (
            <>The on-chain security ID</>
          )}
        </small>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Quantity</Form.Label>
        <Form.Control
          type="number"
          value={tokenAmount}
          onChange={(e) => Number(setTokenAmount(e.target.value))}
          placeholder="Enter amount of shares"
          required
          disabled={loading}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Cash Paid</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={cashPaid}
            onChange={(e) => setCashPaid(e.target.value)}
            placeholder="Enter cash paid"
            required
            disabled={loading}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Cost Basis</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            value={costBasis}
            onChange={(e) => setCostBasis(e.target.value)}
            placeholder="Enter cost basis"
            required
            disabled={loading}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Price Per Share</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control type="number" value={pricePerShare} readOnly disabled />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="switch"
          id="vesting-switch"
          label="Include Vesting Conditions"
          checked={hasVesting}
          onChange={(e) => setHasVesting(e.target.checked)}
        />
      </Form.Group>
      {operation === Operation.Issue && hasVesting && (
        <>
          <h5>Vesting Schedule</h5>
          <Form.Group className="mb-3">
            <Form.Label>Schedule Name</Form.Label>
            <Form.Control
              type="text"
              value={vestingName}
              onChange={(e) => setVestingName(e.target.value)}
              placeholder="Enter vesting name"
              required
              disabled={loading}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              value={vestingDescription}
              onChange={(e) => setVestingDescription(e.target.value)}
              placeholder="Enter vesting description"
              required
              disabled={loading}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setVestingAmount(Number(e.target.value))}
              placeholder="Enter grant amount subject to vesting"
              required
              disabled={loading}
              max={tokenAmount}
              min={0}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Allocation Type</Form.Label>
            <Form.Select
              value={allocationType}
              onChange={(e) => setAllocationType(e.target.value)}
              required
            >
              <option value="CUMULATIVE_ROUNDING">Cumulative Rounding</option>
              <option value="LINEAR">Linear</option>
              {/* Add other allocation types as needed */}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              onChange={(e) => setStartDate(new Date(e.target.value))}
              placeholder="Enter vesting start date"
              required
              disabled={loading}
              min={0}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Conditions</h5>
              <Button
                variant="secondary"
                onClick={handleAddCondition}
                disabled={loading}
              >
                Add Condition
              </Button>
            </div>
            {vestingConditions.map((condition: any, index: number) => (
              <div className="card my-3" key={index}>
                <div className="card-body">
                  <Form.Group className="mb-3">
                    <Form.Label>Condition ID</Form.Label>
                    <Form.Control
                      type="text"
                      value={condition.id}
                      onChange={(e) =>
                        handleConditionChange(index, 'id', e.target.value)
                      }
                      required
                      disabled={loading}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      value={condition.description}
                      onChange={(e) =>
                        handleConditionChange(
                          index,
                          'description',
                          e.target.value
                        )
                      }
                      required
                      disabled={loading}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Trigger Type</Form.Label>
                    <Form.Select
                      value={condition.triggerType}
                      onChange={(e) =>
                        handleConditionChange(
                          index,
                          'triggerType',
                          e.target.value
                        )
                      }
                      required
                      disabled={loading}
                    >
                      <option value="date">Date</option>
                      <option value="event">Event</option>
                    </Form.Select>
                  </Form.Group>
                  {condition.triggerType === 'date' && (
                    <Form.Group className="mb-3">
                      <Form.Label>Trigger Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={condition.triggerDate}
                        onChange={(e) =>
                          handleConditionChange(
                            index,
                            'triggerDate',
                            e.target.value
                          )
                        }
                        required
                        disabled={loading}
                      />
                    </Form.Group>
                  )}
                  {condition.triggerType === 'event' && (
                    <Form.Group className="mb-3">
                      <Form.Label>Event Type</Form.Label>
                      <Form.Control
                        type="text"
                        value={condition.eventType}
                        onChange={(e) =>
                          handleConditionChange(
                            index,
                            'eventType',
                            e.target.value
                          )
                        }
                        placeholder="Enter event type that triggers vesting"
                        required
                        disabled={loading}
                      />
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={(e) =>
                        handleConditionChange(
                          index,
                          'quantity',
                          Number(e.target.value)
                        )
                      }
                      placeholder="Enter quantity for this condition"
                      required
                      disabled={loading}
                    />
                  </Form.Group>
                  <Button
                    variant="outline-danger"
                    onClick={() => handleRemoveCondition(index)}
                    disabled={loading}
                  >
                    <FontAwesomeIcon icon={faTrash} /> Remove
                  </Button>
                </div>
              </div>
            ))}
          </Form.Group>
        </>
      )}
      <Button variant="primary" type="submit" disabled={loading}>
        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Confirm'}
      </Button>
    </Form>
  );
};

export default ManageCertificateForm;
